<template>
    <div id="profileModal" @click="closeModal()" class="bg-[rgb(0,0,0,0.4)] fixed grid z-40 top-0 bottom-0 left-0 right-0">
      <div class="flex items-center w-full justify-center px-4">
        <div id="content_wrapper" class="bg-white w-full rounded-xl py-4 px-4 flex flex-col items-center">
          <div id="profile_header" class="flex flex-col items-center w-full">
            <img src="@/assets/profile.png" class="h-20 w-20 rounded-lg" alt="">
            <p class="mt-4 font-extrabold text-zinc-700 text-2xl tracking-tight">Syamim Shabudin</p>
            <p class="font-['Roboto'] text-zinc-500">Full-Stack Web & Mobile App Developer</p>
          </div>

          <div id="profile_body" class="mt-4 flex flex-col items-center w-full">
            <button @click="whatsAppButton()" class="w-full py-2 px-4 bg-green-600 rounded-lg text-white tracking-wide font-medium font-['Roboto']">
              Contact on WhatsApp
            </button>
            <div class="flex flex-row w-full mt-4">
              <div class="basis-1/3 flex flex-col border-r border-zinc-300">
                <p class="text-center font-['Roboto'] font-semibold tracking-wide text-zinc-600">100+</p>
                <p class="text-center font-['Roboto'] text-sm text-zinc-600">Website/App Build</p>
              </div>
              <div class="basis-1/3 flex flex-col border-r border-zinc-300">
                <p class="text-center font-['Roboto'] font-semibold tracking-wide text-zinc-600">8+</p>
                <p class="text-center font-['Roboto'] text-sm text-zinc-600">Language Used</p>
              </div>
              <div class="basis-1/3 flex flex-col">
                <p class="text-center font-['Roboto'] font-semibold tracking-wide text-zinc-600">6+</p>
                <p class="text-center font-['Roboto'] text-sm text-zinc-600">Framework Learned</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'ProfileModal',
  methods:{
    closeModal(){
      let modal = document.getElementById('profileModal')
      modal.classList.add('hidden')
    },
    whatsAppButton(){
      this.$swal.fire({
        title: 'Alert',
        text: 'Contact Syamim on WhatsApp?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Nope. Not Yet',
        showConfirmButton: true,
        confirmButtonText: 'Yeah. Sure',
        confirmButtonColor: '#f472b6',
        reverseButtons: true,
        focusConfirm: false
      }).then((result)=>{
        if (result.isConfirmed){
          let url = 'https://api.whatsapp.com/send?phone=60172755209&text=%F0%9F%91%8B%F0%9F%8F%BB%20Hi%20syamim.%20Saya%20baru%20lihat%20portfolio%20awak.%20Saya%20ingin%20.....'
          window.open(url,'_blank')
        }
      })
    },
  }
}
</script>