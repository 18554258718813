import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/index.css'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue2-animate/dist/vue2-animate.min.css';

import ProfileModal from './components/profileModal.vue'

createApp(App)
.use(store)
.use(router)
.use(VueSweetalert2)
.component('ProfileModal', ProfileModal)
.mount('#app')
